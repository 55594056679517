import React from 'react'
import Wellcome from '../Wellcome/Wellcome'
import Services from '../Services/Services'
import Banner from '../Banner/Banner.jsx'
import OurPlans from '../OurPlan/OurPlans'
import OutTeam from '../team/OutTeam'
import SupAdminDashboard from '../Dashboard/SupAdminDashboard'
import MyLocation from '../Mylocation/MyLocation'
import OurStory from '../OurStory/OurStory'
import ContactUs from '../forms/ContactUs'

const Home = () => {
  return (
    <div>
     {/* <SupAdminDashboard/> */}
     <Banner/>
<Wellcome/>
      <OurStory/>
      <Services/>
      <OurPlans/>
      {/* <OutTeam/> */}
      <ContactUs/>
    </div>
  )
}

export default Home
