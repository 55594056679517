import { GET_USER_PROFILE_FAIL, GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, GET_USERS_FAIL, GET_USERS_REQUEST, GET_USERS_SUCCESS, LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGIN_USER_FAIL, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, POST_USER_FAIL, 
  POST_USER_REQUEST, POST_USER_SUCCESS, USER_PROFILE_FAIL, USER_PROFILE_REQUEST, USER_PROFILE_SUCCESS } from "../Constants/userConstants";
import axios from 'axios'
export const postUser = (userData) => async (dispatch) => {
  // console.log(userData,'user data');
  
    try {
      dispatch({ type: POST_USER_REQUEST });

      const { data } = await axios.post(`/add/user`,{userData});
console.log(data,'post user data'); 
      dispatch({
        type: POST_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_USER_FAIL,
        payload: error.response,
      });
    }
  };
  export const getUsers = () => async (dispatch) => {
    
    
    try {
      dispatch({ type: GET_USERS_REQUEST });

      const { data } = await axios.get(`/get/allusers`);
console.log(data,'get all users');

      // localStorage.setItem("token", JSON.stringify(data.token));
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: data.users,
      });
    } catch (error) {
      dispatch({
        type: GET_USERS_FAIL,
        payload: error.response.data.message,
      });
    }

  }
  export const loginUser = (userLogin) => async (dispatch) => {
    console.log(userLogin,'user login');
    
    try {
      dispatch({ type: LOGIN_USER_REQUEST });

      const { data } = await axios.post(`/login`,{userLogin});

      localStorage.setItem("user", JSON.stringify(data));
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LOGIN_USER_FAIL,
        payload: error.response.data.message,
      });
    }}
  export const  loadUser = () => async (dispatch) => {

    
    try {
      dispatch({ type: LOAD_USER_REQUEST });
      const {user}=localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {}
      console.log(user,'usr data');
      
      dispatch({
        type: LOAD_USER_SUCCESS,
        payload: user,
      });
    } catch (error) {
      dispatch({
        type: LOAD_USER_FAIL,
        payload: error.response.data.message,
      });
    }}

  

  export const userProfile = (id) => async (dispatch) => {
 
    try {
      dispatch({ type: GET_USER_PROFILE_REQUEST });
      // const config = { 
      //   headers: { "Content-Type": "multipart/form-data" ,
      //     authorization:{
      //       'Bearer':JSON.parse(localStorage.getItem('token'))
      //     }


      //   } 
      
      // };
      const { data } = await axios.get(`/profile/${id}`,);
      console.log(data,'get user profile');
      

      // localStorage.setItem("token", JSON.stringify(data.token));
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_PROFILE_FAIL,
        payload: error.res.data.message,
      });
    }

  }