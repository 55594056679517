import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import logger from 'redux-logger'
import { composeWithDevTools } from "redux-devtools-extension";
import { wellcomeReducers } from "./Redux/Reducers/wellcomeReducers";
import { getSingleServicesReducer, servicesReducers } from "./Redux/Reducers/servicesReducers";
import { userLocationReducer } from "./Redux/Reducers/userLocationReducers";
import { countriesReducers } from "./Redux/Reducers/allCountriesReducers";
import { stateReducers } from "./Redux/Reducers/getStateReducers";
import { districtReducers } from "./Redux/Reducers/districtReducers";
import { allUsersReducers, loadUserReducers, loginUserReducers, userPostReducers, userProfileReducers} from "./Redux/Reducers/userReducers";
import { bannerReducers } from "./Redux/Reducers/bannerReducers";
import { featureReducers } from "./Redux/Reducers/featureReducers";
import { detailsReducers } from "./Redux/Reducers/detailsReducers";
import { getServicesDetailsReducers } from "./Redux/Reducers/serviceDetailsReducer";
import { contactReducers } from "./Redux/Reducers/contactUsReducers";
const reducer = combineReducers({

wellcomeReducers:wellcomeReducers,
servicesReducers:servicesReducers,
getSingleServicesReducer:getSingleServicesReducer,
userLocationReducer:userLocationReducer,
countriesReducers:countriesReducers,
stateReducers:stateReducers,
districtReducers:districtReducers,
userPostReducers:userPostReducers,
bannerReducers:bannerReducers,
featureReducers:featureReducers,
detailsReducers:detailsReducers,
getServicesDetailsReducers:getServicesDetailsReducers,
allUsersReducers:allUsersReducers,
userProfileReducers:userProfileReducers,
loadUserReducers:loadUserReducers,
contactReducers:contactReducers,
loginUserReducers:loginUserReducers
})

const middleware = [thunk];
let initialState={

}

const store = createStore(
  reducer,
  
   // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
 applyMiddleware(...middleware,logger))

//  const store = createStore(reducer,initialState, composeWithDevTools(
//   applyMiddleware(...middleware,logger),
//   // other store enhancers if any
// ));
export default store;