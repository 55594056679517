import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const ReadMore = () => {
  const [detail,setDetail]=useState('')
  const [loading, setLoading] = useState(false);
    const {id}=useParams()
   
    useEffect(() => {
      setLoading(true);
   
        const data=axios.get(`/get/single/details/${id}`)
        console.log(data,'details');
        
        setDetail(data.details)
      
  }, [id]);


// abc()

    
    
  return (
    <div>
       <div id="about">
            <div class="container-fluid">
                <div class="section-header">
                    <h2>{detail?.title}</h2>
                </div>
                <div className="image-div">
                  <img src={`/uploads/pointofsale/${detail?.image} `}alt={detail?.title}/>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="welcome m-auto">
                            <p>
                            {detail?.details}               </p>
                            {/* <a class="btn" href="#">Read More</a> */}
                        </div>
                    </div>
                </div></div></div>
  
    </div>
  )
}

export default ReadMore
