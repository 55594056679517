import { GET_SERVICE_DETAILS_FAIL, GET_SERVICE_DETAILS_REQUEST, GET_SERVICE_DETAILS_SUCCESS, } from "../Constants/serviceDetailsConstant";

export const getServicesDetailsReducers = (state = { servicesDetails: [] }, action) => {
    switch (action.type) {
      case GET_SERVICE_DETAILS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_SERVICE_DETAILS_SUCCESS:
        console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          servicesDetails: action.payload,
        };
      case GET_SERVICE_DETAILS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };
