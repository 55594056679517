import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import './navbar.css'
import LoginModel from '../models/LoginModel'
import Login from '../forms/Login'
import { useSelector } from 'react-redux'
import HomeIcon from '@mui/icons-material/Home';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ContactsIcon from '@mui/icons-material/Contacts';
import InfoIcon from '@mui/icons-material/Info';
const Navbar = () => {
  
    const {userLoad}=useSelector(state=>state.loadUserReducers)
  return (
  <>
  <div className="navbar-header">
    <div className="nav-logo">
    <NavLink to='/'><img src='./Images/ush.jpg' alt='ush'/></NavLink> 

    </div>
    <div className="nav-bar">
<ul className='d-flex flex-row'>
<li className='text-primary m-1'><NavLink to='/'><HomeIcon/><p>Home</p></NavLink></li>
<li className='text-primary m-1'><NavLink to='/our-services'><HomeRepairServiceIcon/><p>Services</p></NavLink></li>
<li className='text-primary m-1'><NavLink to='/about-us' ><InfoIcon/><p>About us</p></NavLink></li>
<li className='text-primary m-1'><NavLink to='/contact-us'><ContactsIcon/><p>Contact Us</p></NavLink></li>
</ul>
    </div>
    <div className="apply">
      <button className='btn btn-warning'><Link to='/apply' style={{color:'purple',fontWeight:'bold'}}>Apply</Link></button></div>
  </div>
  
  </>
   

  )
}

export default Navbar
