import axios from "axios";
import { USER_LOCATION_FAIL, USER_LOCATION_REQUEST, USER_LOCATION_SUCCESS } from "../Constants/userLocation";

export const getUserLocation = () => async (dispatch) => {
    try {
      dispatch({ type: USER_LOCATION_REQUEST });
    //   const config = {
    //     headers: {
    //    "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    //     },
    //   };

      const {data} = await axios.get(`https://geo.ipify.org/api/v2/country?apiKey=YOUR_API_KEY&ipAddress=8.8.8.8`);
console.log(data,'user location');

      dispatch({
        type: USER_LOCATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LOCATION_FAIL,
        // payload: error.response.data.message,
      });
    }
  };

