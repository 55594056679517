import React from 'react'
import './footer.css'
import LanguageIcon from '@mui/icons-material/Language';
import ApiIcon from '@mui/icons-material/Api';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import PasswordIcon from '@mui/icons-material/Password';
import HomeIcon from '@mui/icons-material/Home';
const Footer = () => {
  let slug='Software-Development'
  return (
    <div>
      <div className="footer-div">
<div className="footer-1">
  
<ul>
    <li><Link to={`/service/${slug}`}><LanguageIcon/>Website Development</Link></li>
    <li><Link to='/service/Software-Development'><ApiIcon/>Software Development</Link></li>
    <li><Link to='/service/Web-Designing' target='_blank'><VerticalSplitIcon/>web Designing</Link></li>
    <li><Link to='/service/Software-Development' target='_blank'><SocialDistanceIcon/>Social Marketing</Link></li>
    <li><Link to='/service/Graphic-Desinging' target='_blank'><VideoCameraBackIcon/>Graphic Designing</Link></li>
    <li><Link to='/service/SEO' target='_blank'>< PersonSearchIcon/>SEO</Link></li>
</ul>


</div>
<div className="footer-2">
<ul>
<li><Link to='https://www.youtube.com/@uniquesoftwarehouse' target='_blank'><YouTubeIcon/>YouTube</Link></li>
<li><Link to='https://www.facebook.com/profile.php?id=61562322567895' target='_blank'><FacebookIcon/>FaceBook</Link></li>
<li><Link to='https://www.instagram.com/uniquesoftwarehouse/' target='_blank'><InstagramIcon/>InstaGram</Link></li>
<li><Link to='https://www.linkedin.com/in/taniya-malik-3a0146229/' target='_blank'><LinkedInIcon/>Linked IN</Link></li>
<li><a href='tel:00923144763232'><WhatsAppIcon/>WhatsApp</a></li>

</ul>

</div>
<div className="footer-3">
    <ul>
        <li><Link to='/our/location' target='_blank'><LocationOnIcon/> Office#. 15 Ground Floor Ghakar Plaza,</Link></li>
        <li><Link to='/our/location' target='_blank'><LocationOnIcon/>behind SilkWay Plaza, Haripur,KPK, Pakistan</Link></li>
 
 
        <li>< EmailIcon/>uniquesoftwarehouses@gmail.com</li>
        <li><a href='tel:00923009309819'><LocalPhoneIcon/>+ 92 300 930 98 19</a></li>
        <li><a href='tel:00923144763232'><LocalPhoneIcon/>+ 92 314 476 32 32</a></li>
       
    </ul>
    
    
    

 
 </div>


      </div>
    </div>
  )
}

export default Footer
