import { POST_CONTACT_US_FAIL, POST_CONTACT_US_REQUEST, POST_CONTACT_US_SUCCESS } from "../Constants/contactUsConstant";

export const contactReducers = (state = { contact: {} }, action) => {
    switch (action.type) {
      case POST_CONTACT_US_REQUEST:
        return {
          ...state,
          loading: true,
          success:false
        };
      case POST_CONTACT_US_SUCCESS:
        console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          success:true,
          contact: action.payload,
        };
      case POST_CONTACT_US_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };