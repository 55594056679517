import React from 'react'
import './ourplan.css'
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
const PlanProps = (item) => {
  return (
    <div>
      <li className='d-flex flex-row w-100'><div className='name-plan'>({item.sno})...{item.name}</div>
      <div className='icon-plan'>
        {item.icon==='true'?<DoneIcon style={{color:'green'}}/>:<CancelIcon style={{color:'red'}}/>}
        
        
        </div></li>
    </div>
  )
}

export default PlanProps
