import axios from "axios";
import { GET_FEATURES_FAIL, GET_FEATURES_REQUEST, GET_FEATURES_SUCCESS } from "../Constants/featureConstants";

export const getfeatures = (slug) => async (dispatch) => {
    try {
      dispatch({ type: GET_FEATURES_REQUEST });
      const { data } = await axios.get(`/feature/${slug}`);
console.log(data,'GE feature data');

      dispatch({
        type: GET_FEATURES_SUCCESS,
        payload: data.feature,
      });
    } catch (error) {
      dispatch({
        type: GET_FEATURES_FAIL,
        payload: error.response.data.message,
      });
    }
  };