import logo from './logo.svg';
import './App.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getWellcome } from './Redux/Actions/wellcomeActions';
import store from './store'
import './bootstrap.min.css'
import Wellcome from './components/Wellcome/Wellcome';
import { getServices } from './Redux/Actions/servicesAction';
import Services from './components/Services/Services';
import SingleService from './components/Services/SingleService';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import Images from './components/Images/Images';
import Test from './components/Images/Test';
import './components/Navbar/navbar.css'
import ServicesForm from './components/forms/ServicesForm';
import { getUserLocation } from './Redux/Actions/getUserLocation';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from './Redux/Actions/getCountriesAction';
import Register from './components/forms/Register';
import Login from './components/forms/Login';
import { loadUser, userProfile } from './Redux/Actions/userActions';
import Home from './components/Home/Home';
import { getBanner } from './Redux/Actions/bannerAction';
import Footer from './components/footer/Footer';
import ReadMore from './components/Services/ReadMore';
import SupAdminDashboard from './components/Dashboard/SupAdminDashboard';
import Users from './components/users/Users';
import UserProfile from './components/users/UserProfile';
import Header from './components/Header/Header.jsx'
import TestVideo from './TestVideo.jsx';
import Testimg from './Testimg.jsx';
import FeebackForm from './components/forms/FeebackForm.jsx';
import LoginModel from './components/models/LoginModel.jsx';
import UpdateProfile from './components/forms/UpdateProfile.jsx';
import OurPlans from './components/OurPlan/OurPlans.jsx';
import ContactUs from './components/forms/ContactUs.jsx';
import About from './components/Aboutus/About.jsx';
import MyLocation from './components/Mylocation/MyLocation.jsx';
import OurStory2 from './components/OurStory/OurStory2.jsx';
import NewNavbar from './components/Navbar/NewNavbar.jsx';
function App() {
  const dispatch=useDispatch()
  store.dispatch(getWellcome())
  store.dispatch(getServices())
  store.dispatch(getCountries())
  store.dispatch(getBanner())
  store.dispatch(loadUser())
  useEffect(()=>{
  dispatch(userProfile())
  dispatch(getUserLocation())
  
  
  },[dispatch])

  
  return (
    <div className="App">
{/* <Header/> */}
<NewNavbar/>
        <Navbar/>

      
        <Routes>
          
          <Route path='/service/:slug' element={<SingleService/>}/>
          <Route path='/apply' element={<Register/>}/>
          <Route path='/service/:slug/:id' element={<ReadMore/>}/>
          {/* <Route path='/login' element={<Login/>}/> */}
          {/* <Route path='/update/profile' element={<UpdateProfile/>}/> */}
          <Route path='/our-services' element={<Services/>}/>
          {/* <Route path='/get/users' element={<Users/>}/> */}
          {/* <Route path='/profile' element={<UserProfile/>}/> */}
          <Route path='/contact-us' element={<ContactUs/>}/>
          <Route path='/our/location' element={<MyLocation/>}/>
          {/* <Route path='/dashboard' element={<Testimg/>}/> */}
          {/* <Route path='/dashboard' element={<SupAdminDashboard/>}/> */}
          <Route path='/' element={<Home/>}/>
          <Route path='/about-us' element={<About/>}/>
          <Route path='/our/story' element={<OurStory2/>}/>
          {/* <Route path='/services' element={<TestVideo/>}/> */}
          {/* <Route path='/service/upload' element={<ServicesForm/>}/> */}

        </Routes>
        <Footer/>
      {/* </Router> */}
      
    </div>
  );
}

export default App;
