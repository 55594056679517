export const GET_SERVICES_REQUEST = "GET_SERVICES_REQUEST";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";

export const GET_SINGLE_SERVICES_REQUEST = "GET_SINGLE_SERVICES_REQUEST";
export const GET_SINGLE_SERVICES_SUCCESS = "GET_SINGLE_SERVICES_SUCCESS";
export const GET_SINGLE_SERVICES_FAIL = "GET_SINGLE_SERVICES_FAIL";

export const UPDATE_SERVICES_REQUEST = "UPDATE_SERVICES_REQUEST";
export const UPDATE_SERVICES_SUCCESS = "UPDATE_SERVICES_SUCCESS";
export const UPDATE_SERVICES_FAIL = "UPDATE_SERVICES_FAIL";