import axios from 'axios'
import { POST_CONTACT_US_FAIL, POST_CONTACT_US_REQUEST, POST_CONTACT_US_SUCCESS, POST_FEEDBACK_FAIL, POST_FEEDBACK_REQUEST, POST_FEEDBACK_SUCCESS } from '../Constants/contactUsConstant';
export const postContactUs = (values) => async (dispatch) => {
  console.log(values,'values');
  
    try {
      dispatch({ type: POST_CONTACT_US_REQUEST });


      const { data } = await axios.post(`/post/contactus`,values);
console.log(data,'banner data');

      dispatch({
        type: POST_CONTACT_US_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_CONTACT_US_FAIL,
        payload: error.response.data.message,
      });
    }
  };
export const postFeedBack = (values) => async (dispatch) => {
  console.log(values,'form values values');
  
    try {
      dispatch({ type: POST_FEEDBACK_REQUEST });


      const { data } = await axios.post(`/post/feedback`,values);
console.log(data,'banner data');

      dispatch({
        type: POST_FEEDBACK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_FEEDBACK_FAIL,
        payload: error.response.data.message,
      });
    }
  };
