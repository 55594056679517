import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleService } from '../../Redux/Actions/servicesAction'
import { getDetails, getSingleDetail } from '../../Redux/Actions/detailsActions'
import { useNavigate } from 'react-router-dom'
import { getfeatures } from '../../Redux/Actions/featureActions'
import './services.css'

import { getServiceDetails } from '../../Redux/Actions/serviceDetailsAction'

const Services = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { services } = useSelector(state => state.servicesReducers)
  // console.log(services,'services ');
  const handleClick = (e) => {
    dispatch(getSingleService(e))
    dispatch(getDetails(e))
    dispatch(getServiceDetails(e))
    dispatch(getfeatures(e))
    navigate(`/service/${e}`)
  }
const data=[
  {s:'1'},
  {s:'2'},
  {s:'3'},
  {s:'4'},
  {s:'5'},
]

  return (
    <div>
      <div id="services" >
        <div class="container-fluid">
          <div class="section-header">
            <h2 className='text-light'>Our Services</h2>
            <p>
             </p>          </div>
          <div class="row">
            {services?.map((item) => {
              return (
                <>
                  <div class="col-md-6 col-lg-4" onClick={()=>{handleClick(item.slug)}} style={{cursor:'pointer'}}>
                    <div class="service-col">
                      <div class="service-icon">

                      <img src={`/uploads/services/${item.image}`} alt={item.name}/>

                      </div>
                      <h3>  {item.name}</h3>
                      <div class="service-detail">
                        {item?.details.length > 220 ? <p>{item.details.substring(0, 120)}...</p> : <p>{item.details}</p>}                    </div>
                    </div>
                  </div>


                </>
              )
            })}
          </div>
        </div>
      </div>
      {/* <div className="services">
        <div className="services-div">
      {services?.map((item)=>{
        return(
            <>
            <div className="service-map-div"onClick={()=>{handleClick(item.slug)}}>
              {item.description}
              <div className="service-image-div">
<img src={`/uploads/services/${item.image}`} alt={item.name}/>
              </div>
            
            <div className='services-name-div'>
            {item.name}
            <div className='details-div'><p>{item?.details.length>220?<p>{item.details.substring(0,220)}...</p>:<p>{item.details}</p>}</p></div>
            <div className="read-more">Read More</div>
            </div>
            
            </div>
            </>
        )
      })}
    </div>
    </div> */}
    </div>
  )
}

export default Services
