
export const data=
   [
    
      {icon:'true',category:'website',title:'static',name:"5-10 Pages"},
      {icon:'true',category:'website',title:'static',name:"Custom Design"},
      {icon:'true',category:'website',title:'static',name:"Completely Responsive"},
      {icon:'true',category:'website',title:'static',name:"SEO Friendly"},
      {icon:'true',category:'website',title:'static',name:"Google Analytice Integration"},
      {icon:'true',category:'website',title:'static',name:"Social Media Sharing Options"},
      {icon:'false',category:'website',title:'static',name:"1 year Free Hosting"},
      {icon:'false',category:'website',title:'static',name:"1 year Free Domain"}, 
      {icon:'false',category:'website',title:'static',name:"SSL Certificate"},
      {icon:'false',category:'website',title:'static',name:"payment Gateways"},

      {icon:'true',category:'website',title:'dynamic',name:"Unlimited Pages"},
      {icon:'true',category:'website',title:'dynamic',name:"Custom Design"},
      {icon:'true',category:'website',title:'dynamic',name:"Completely Responsive"},
      {icon:'true',category:'website',title:'dynamic',name:"SEO Friendly"},
      {icon:'true',category:'website',title:'dynamic',name:"Google Analytice Integration"},
      {icon:'true',category:'website',title:'dynamic',name:"Social Media Sharing Options"},
      {icon:'true',category:'website',title:'dynamic',name:"1 year Free Hosting"},
      {icon:'true',category:'website',title:'dynamic',name:"1 year Free Domain"}, 
      {icon:'true',category:'website',title:'dynamic',name:"SSL Certificate"},
      {icon:'false',category:'website',title:'dynamic',name:"payment Gateways"},

    {icon:'true',category:'website',title:'ultimate',name:"Unlimited Pages"},
    {icon:'true',category:'website',title:'ultimate',name:" Custom Design"},
    {icon:'true',category:'website',title:'ultimate',name:" Completely Responsive"},
    {icon:'true',category:'website',title:'ultimate',name:" SEO Friendly"},
    {icon:'true',category:'website',title:'ultimate',name:" Google Analytice Integration"},
    {icon:'true',category:'website',title:'ultimate',name:" Social Media Sharing Options"},
    {icon:'true',category:'website',title:'ultimate',name:" 1 year Free Hosting"},
    {icon:'true',category:'website',title:'ultimate',name:" 1 year Free Domain"}, 
    {icon:'true',category:'website',title:'ultimate',name:"SSL Certificate"},
    {icon:'true',category:'website',title:'ultimate',name:" payment Gateways"},

    {icon:'true',category:'videoEditing',title:'static',name:"5 videos in a weeak"},
    {icon:'true',category:'videoEditing',title:'static',name:"48 hours turnout time"},
    {icon:'true',category:'videoEditing',title:'static',name:"unlimited revisions"},
    {icon:'true',category:'videoEditing',title:'static',name:"dedicated video editor"},
    {icon:'true',category:'videoEditing',title:'static',name:"free subtitle"},
    {icon:'true',category:'videoEditing',title:'static',name:"4 social media booster"},
    {icon:'true',category:'videoEditing',title:'static',name:"video proofing"},
    {icon:'true',category:'videoEditing',title:'static',name:"world class support"},
    {icon:'true',category:'videoEditing',title:'static',name:"All project files included"},
    {icon:'true',category:'videoEditing',title:'static',name:"Dedicated Cloud Storage"},
    {icon:'true',category:'videoEditing',title:'static',name:"14 Day money back guarantee"},
    {icon:'true',category:'videoEditing',title:'static',name:"cancel anytime"},

    {icon:'true',category:'videoEditing',title:'dynamic',name:"8 videos in a week"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"48 hours turnout time"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"unlimited revisions"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"dedicated video editor"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"free subtitle"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"4 social media booster"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"video proofing"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"world class support"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"All project files included"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"Dedicated Cloud Storage"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"14 Day money back guarantee"},
    {icon:'true',category:'videoEditing',title:'dynamic',name:"cancel anytime"},

    {icon:'true',category:'videoEditing',title:'ultimate',name:"15 videos in a week"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"48 hours turnout time"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"unlimited revisions"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"dedicated video editor"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"free subtitle"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"4 social media booster"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"video proofing"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"world class support"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"All project files included"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"Dedicated Cloud Storage"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"14 Day money back guarantee"},
    {icon:'true',category:'videoEditing',title:'ultimate',name:"cancel anytime"},

    {icon:'true',category:'seo',title:'static',name:'Up to 1-3 Keywords'},
    {icon:'true',category:'seo',title:'static',name:'Google Analytics & GMB Setup'},
    {icon:'true',category:'seo',title:'static',name:'Keyword Research'},
    {icon:'true',category:'seo',title:'static',name:'Page Title & Meta Descriptions'},
    {icon:'true',category:'seo',title:'static',name:'Monthly Reporting'},
    {icon:'true',category:'seo',title:'static',name:'Sitemap/Robots.txt Creation & Analysis'},
    {icon:'false',category:'seo',title:'static',name:'On Page Optimisation'},
    {icon:'false',category:'seo',title:'static',name:'Blog Articles'},
    {icon:'false',category:'seo',title:'static',name:'Building Local Citations'},
    {icon:'false',category:'seo',title:'static',name:'Backlink Strategy'},
    {icon:'false',category:'seo',title:'static',name:'Social Media Management'},
    {icon:'false',category:'seo',title:'static',name:'Press Releases'},

    {icon:'true',category:'seo',title:'dynamic',name:'Up to 5-10 Keywords'},
    {icon:'true',category:'seo',title:'dynamic',name:'Google Analytics & GMB Setup'},
    {icon:'true',category:'seo',title:'dynamic',name:'Keyword Research'},
    {icon:'true',category:'seo',title:'dynamic',name:'Page Title & Meta Descriptions'},
    {icon:'true',category:'seo',title:'dynamic',name:'Monthly Reporting'},
    {icon:'true',category:'seo',title:'dynamic',name:'Sitemap/Robots.txt Creation & Analysis'},
    {icon:'true',category:'seo',title:'dynamic',name:'On Page Optimisation'},
    {icon:'true',category:'seo',title:'dynamic',name:'2x 500-word articles per month'},
    {icon:'flase',category:'seo',title:'dynamic',name:'Building Local Citations'},
    {icon:'flase',category:'seo',title:'dynamic',name:'Backlink Strategy'},
    {icon:'flase',category:'seo',title:'dynamic',name:'Social Media Management'},
    {icon:'flase',category:'seo',title:'dynamic',name:'Press Release'},
    
    {icon:'true',category:'seo',title:'ultimate',name:'Up to 11-15 Keywords'},
    {icon:'true',category:'seo',title:'ultimate',name:'Google Analytics & GMB Setup'},
    {icon:'true',category:'seo',title:'ultimate',name:' Keyword Research'},
    {icon:'true',category:'seo',title:'ultimate',name:'Page Title & Meta Descriptions'},
    {icon:'true',category:'seo',title:'ultimate',name:' Monthly Reporting'},
    {icon:'true',category:'seo',title:'ultimate',name:'Sitemap/Robots.txt Creation & Analysis'},
    {icon:'true',category:'seo',title:'ultimate',name:'On Page Optimisation'},
    {icon:'true',category:'seo',title:'ultimate',name:'4x 500-word articles per month'},
    {icon:'true',category:'seo',title:'ultimate',name:'Building Local Citations'},
    {icon:'true',category:'seo',title:'ultimate',name:'Backlink Strategy'},
    {icon:'true',category:'seo',title:'ultimate',name:'Social Media Management'},
    {icon:'true',category:'seo',title:'ultimate',name:'Press Releases'},

 {icon:'true',category:'softwareDevelopment',title:'static',name:'Real-time data access, automatic software.'},
 {icon:'true',category:'softwareDevelopment',title:'static',name:'Automatic software updates.'},
 {icon:'true',category:'softwareDevelopment',title:'static',name:'Ability to accept multiple payment options.'},
 {icon:'true',category:'softwareDevelopment',title:'static',name:'Remote access from anywhere.'},
 {icon:'true',category:'softwareDevelopment',title:'static',name:'Lower ongoing maintenance.'},
 {icon:'true',category:'softwareDevelopment',title:'static',name:'Advanced reporting and analytics.'},
 {icon:'true',category:'softwareDevelopment',title:'static',name:'Integration with other software systems'},
 {icon:'true',category:'softwareDevelopment',title:'static',name:'No dependence on internet connectivity.'},
 {icon:'true',category:'softwareDevelopment',title:'static',name:'One-time pricing models.'},
 {icon:'true',category:'softwareDevelopment',title:'static',name:'Lower ongoing maintenance and support requirements'},


 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'Real-time data access, automatic software.'},
 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'Automatic software updates.'},
 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'Ability to accept multiple payment options.'},
 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'Remote access from anywhere.'},
 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'Lower ongoing maintenance.'},
 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'Advanced reporting and analytics.'},
 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'Integration with other software systems'},
 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'No dependence on internet connectivity.'},
 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'One-time pricing models.'},
 {icon:'true',category:'softwareDevelopment',title:'dynamic',name:'Lower ongoing maintenance and support requirements'},

 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'Real-time data access, automatic software.'},
 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'Automatic software updates.'},
 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'Ability to accept multiple payment options.'},
 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'Remote access from anywhere.'},
 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'Lower ongoing maintenance.'},
 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'Advanced reporting and analytics.'},
 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'Integration with other software systems'},
 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'No dependence on internet connectivity.'},
 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'One-time pricing models.'},
 {icon:'true',category:'softwareDevelopment',title:'ultimate',name:'Lower ongoing maintenance and support requirements'},


// // final seo 
// Up to 11-15 Keywords
// Google Analytics & GMB Setup
// Keyword Research
// Page Title & Meta Descriptions
// Monthly Reporting
// Sitemap/Robots.txt Creation & Analysis
// On Page Optimisation
// 4x 500-word articles per month
// Building Local Citations
// Backlink Strategy
// Social Media Management
// Press Releases
   ]













// static=["10 to 50 Pages",
// "Custom Design",
// "Completely Responsive",
// "SEO Friendly",
// "Google Analytice Integration",
// "Social Media Sharing Options",
// "1 year Free Hosting",
// "1 year Free Domain",
// "SSL Certificate"],
// dynamic=[
//     Unlimited Pages
//     Custom Design
//     Completely Responsive
//     SEO Friendly
//     Google Analytice Integration
//     Social Media Sharing Options
//     1 year Free Hosting
//     1 year Free Domain
//     SSL Certificate
// ]
// ultimate=[
//    
// ]