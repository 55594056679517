import { GET_COUNTRIES_FAIL, GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS } from "../Constants/countryConstants";

export const countriesReducers = (state = { allCountries: [] }, action) => {
    switch (action.type) {
      case GET_COUNTRIES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_COUNTRIES_SUCCESS:
        console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          allCountries: action.payload,
        };
      case GET_COUNTRIES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };