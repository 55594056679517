import { GET_SERVICES_FAIL, GET_SERVICES_REQUEST, GET_SERVICES_SUCCESS, GET_SINGLE_SERVICES_FAIL, GET_SINGLE_SERVICES_REQUEST, GET_SINGLE_SERVICES_SUCCESS } from "../Constants/servicesConstants";
import axios from 'axios'
export const getServices = () => async (dispatch) => {
    try {
      dispatch({ type: GET_SERVICES_REQUEST });


      const { data } = await axios.get(`/get/services`);
console.log(data,'GET_SERVICES data');

      dispatch({
        type: GET_SERVICES_SUCCESS,
        payload: data.service,
      });
    } catch (error) {
      dispatch({
        type: GET_SERVICES_FAIL,
        payload: error.response.data.message,
      });
    }
  };
export const getSingleService = (slug) => async (dispatch) => {
    try {
      dispatch({ type: GET_SINGLE_SERVICES_REQUEST });


      const { data } = await axios.get(`/get/service/${slug}`);
console.log(data,'GET_SERVICES data');

      dispatch({
        type: GET_SINGLE_SERVICES_SUCCESS,
        payload: data.service,
      });
    } catch (error) {
      dispatch({
        type: GET_SINGLE_SERVICES_FAIL,
        payload: error.response.data.message,
      });
    }
  };
