import React from 'react'
import './banner.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';
import BannerImages from './banner.json'
const Banner = () => {
    const {banner}=useSelector(state=>state.bannerReducers)
    console.log(banner,'banner');
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 7000,
        arrorws:true,
        autoplay: true,
        pauseOnHover: true,
        fade: true,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
      };
      return (
        <div>
          <Slider {...settings}>
    {BannerImages?.map((item)=>{
      // console.log(item,'ite,s');
      
      return(
        <>
        
        <div className="slider-div mx-auto">
        <img src={item.image} alt={item.title} className='mx-auto'/>
        {/* <img src={`/uploads/banner/${item?.item}`} alt={item} className='mx-auto'/> */}
        
        </div>
          <div className="banner-title-div text-center">

{/* <div className="title-inner"><h1 className='text-center'>{item.title}</h1></div> */}

          </div>
        </>
       
      )
    })}
     </Slider>
    
          
        </div>
      )
}

export default Banner
