import React from 'react'
import {useSelector} from 'react-redux'
import './wellcome.css'
const Wellcome = () => {
  const {wellcome}=useSelector(state=>state.wellcomeReducers)
  return (
    <>
     <div id="about">
            <div class="container-fluid">
                <div class="section-header">
                    <h2>{wellcome?.name}</h2>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="welcome m-auto">
                            <p>
                            {wellcome?.description}               </p>
                            {/* <a class="btn" href="#">Read More</a> */}
                        </div>
                    </div>
                </div></div></div>
                </>
  )
}

export default Wellcome
