import { GET_COUNTRIES_FAIL, GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS } from "../Constants/countryConstants";
import axios from 'axios'
export const getCountries = () => async (dispatch) => {
    try {
      dispatch({ type: GET_COUNTRIES_REQUEST });


      const {data} = await axios.get('/get/countries');

console.log(data,'all countries');

      dispatch({
        type: GET_COUNTRIES_SUCCESS,
        payload: data.countries,
      });
    } catch (error) {
      dispatch({
        type: GET_COUNTRIES_FAIL,
        payload: error.response.data.message,
      });
    }
  };
