import { GET_STATE_FAIL, GET_STATE_REQUEST, GET_STATE_SUCCESS } from "../Constants/stateConstant";
import axios from 'axios'
export const getStates = (countryCode) => async (dispatch) => {
    try {
      dispatch({ type: GET_STATE_REQUEST });


      const {data} = await axios.post('/get/states',{countryCode:countryCode});

console.log(data,'all states');

      dispatch({
        type: GET_STATE_SUCCESS,
        payload: data.state,
      });
    } catch (error) {
      dispatch({
        type: GET_STATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };
