import { WELLCOME_FAIL, WELLCOME_REQUEST, WELLCOME_SUCCESS } from "../Constants/WellcomeConstants";

export const wellcomeReducers = (state = { wellcome: {} }, action) => {
    switch (action.type) {
      case WELLCOME_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case WELLCOME_SUCCESS:
        // console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          wellcome: action.payload.wellcome,
        };
      case WELLCOME_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };