import { GET_BANNER_FAIL, GET_BANNER_REQUEST, GET_BANNER_SUCCESS } from "../Constants/bannerConstants";

export const bannerReducers = (state = { banner: [] }, action) => {
    switch (action.type) {
      case GET_BANNER_REQUEST:
        return {
          ...state,
          loading: true,
          success:false
        };
      case GET_BANNER_SUCCESS:
        console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          success:true,
          banner: action.payload,
        };
      case GET_BANNER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };