import axios from "axios";
import { GET_SERVICE_DETAILS_FAIL, GET_SERVICE_DETAILS_REQUEST, GET_SERVICE_DETAILS_SUCCESS } 
from "../Constants/serviceDetailsConstant";

export const getServiceDetails = (slug) => async (dispatch) => {
    try {
      dispatch({ type: GET_SERVICE_DETAILS_REQUEST });
      const { data } = await axios.get(`/services/${slug}`);
console.log(data,'GE details SERVICES data');

      dispatch({
        type: GET_SERVICE_DETAILS_SUCCESS,
        payload: data.combine,
      });
    } catch (error) {
      dispatch({
        type: GET_SERVICE_DETAILS_FAIL,
        payload: error.response.data.message,
      });
    }
  };