import { WELLCOME_FAIL, WELLCOME_REQUEST, WELLCOME_SUCCESS } from "../Constants/WellcomeConstants";
import axios from 'axios'
export const getWellcome = () => async (dispatch) => {
    try {
      dispatch({ type: WELLCOME_REQUEST });


      const { data } = await axios.get(`/get/wellcome`);
console.log(data,'wellcome data');

      dispatch({
        type: WELLCOME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WELLCOME_FAIL,
        payload: error.response.data.message,
      });
    }
  };
