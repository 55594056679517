import axios from 'axios'
import { GET_BANNER_FAIL, GET_BANNER_REQUEST, GET_BANNER_SUCCESS } from '../Constants/bannerConstants';
export const getBanner = () => async (dispatch) => {
    try {
      dispatch({ type: GET_BANNER_REQUEST });


      const { data } = await axios.get(`/get/banner`);
console.log(data.banner,'banner data');

      dispatch({
        type: GET_BANNER_SUCCESS,
        payload: data.banner,
      });
    } catch (error) {
      dispatch({
        type: GET_BANNER_FAIL,
        payload: error.response.data.message,
      });
    }
  };
