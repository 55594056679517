import React, { useState } from 'react'
import './ourplan.css'
import {data} from './plan'
import PlanProps from './PlanProps';
import { button } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import ApiIcon from '@mui/icons-material/Api';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
const OurPlans = () => {
const [changeValue,setChangeValue]=useState('website')
const [innerText,setInnerText]=useState('Web Development')
const handleChange=(e)=>{
   setChangeValue(e.target.value)
setInnerText(e.target.innerHTML)

    
}

    
  return (
    <div>
      <div id="services" >
       <div class="section-header">
            <h2 className='text-light'>Our Plans</h2>
            <p>
             </p>          </div>
      <div className="nav-menu">
<ul className='d-flex flew-row m-2 justify-content-between'>

<li className='ms-3'><button className='' style={{border:'none',background:'none',fontWeight:'600'}}
value='website' onClick={(e)=>handleChange(e)}>Web Development</button></li>
  <li className='ms-3'><button className='' style={{border:'none',background:'none',fontWeight:'600'}}
  value='softwareDevelopment' onClick={(e)=>handleChange(e)}>Software Development</button></li>
     <li className='ms-3'><button className='' style={{border:'none',background:'none',fontWeight:'600'}}
   value='seo' onClick={(e)=>handleChange(e)}>SEO</button></li>
   <li className='ms-3'><button className='' style={{border:'none',background:'none',fontWeight:'600'}}
   value='videoEditing' onClick={(e)=>handleChange(e)}>Video Editing</button></li>
   {/* <li className='ms-3'><button to='/service/upload'>Upload service</button></li> */}

</ul>
</div>
<div class="section-header">
                    <h2>{innerText} Packages</h2>
                </div>
{/* <div className="inner-html"><h2 className='text-light m-3 bg-dark'>{innerText} Packages</h2></div> */}
      <div className="our-plans m-3">
 
<div className="plan-card">
{/* <h2 style={{color:'red',marginBottom:'20px'}}>Static</h2> */}
<div class="section-header">
                    <h2 style={{color:'dodgerblue',marginBottom:'20px'}}>static</h2>
                </div>
   {data?.filter((item)=>item.title==='static' && item.category===changeValue).map((item,i)=>{
    console.log(item,'items');
    
    return(
        <>
        <ul>
        <li><PlanProps key={i} sno={i+1} name={item.name} icon={item.icon}/></li>
                   
        </ul>
        </>
    )
   })}



</div>
<div className="plan-card">
<div class="section-header">
                    <h2 style={{color:'dodgerblue',marginBottom:'20px'}}>Dynamic</h2>
                </div>
   {data?.filter((item)=>item.title==='dynamic' && item.category===changeValue).map((item,i)=>{
    console.log(item,'items');
    
    return(
        <>
        <ul>
        <li><PlanProps key={i} sno={i+1} name={item.name} icon={item.icon}/></li>
                   
        </ul>
        </>
    )
   })}



</div>
<div className="plan-card">
<div class="section-header">
                    <h2 style={{color:'dodgerblue',marginBottom:'20px'}}>ultimate</h2>
                </div>
   {data?.filter((item)=>item.title==='ultimate' && item.category===changeValue).map((item,i)=>{

    
    return(
        <>
        <ul>
        <li><PlanProps key={i} sno={i+1} name={item.name} icon={item.icon}/></li>
                   
        </ul>
        </>
    )
   })}



</div>


    </div>
    </div>
    </div>
  )
}

export default OurPlans
