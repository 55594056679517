
import { GET_DISTRICT_FAIL, GET_DISTRICT_REQUEST, GET_DISTRICT_SUCCESS } from "../Constants/districtConstant";
import axios from 'axios'
export const getdistrict = (isoCode) => async (dispatch) => {
    console.log(isoCode,'iso code');
    
    try {
      dispatch({ type: GET_DISTRICT_REQUEST });


      const {data}= await axios.post('/get/district',isoCode);

console.log(data,'all district');

      dispatch({
        type: GET_DISTRICT_SUCCESS,
        payload: data.district,
      });
    } catch (error) {
      dispatch({
        type: GET_DISTRICT_FAIL,
        payload: error.response.data.message
      });
    }
  };
