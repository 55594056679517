import { GET_SERVICES_FAIL, GET_SERVICES_REQUEST, GET_SERVICES_SUCCESS, GET_SINGLE_SERVICES_FAIL, GET_SINGLE_SERVICES_REQUEST, GET_SINGLE_SERVICES_SUCCESS } from "../Constants/servicesConstants";

export const servicesReducers = (state = { services: [] }, action) => {
    switch (action.type) {
      case GET_SERVICES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_SERVICES_SUCCESS:
        console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          services: action.payload,
        };
      case GET_SERVICES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };
export const getSingleServicesReducer = (state = { service: {} }, action) => {
    switch (action.type) {
      case GET_SINGLE_SERVICES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_SINGLE_SERVICES_SUCCESS:
        // console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          service: action.payload,
        };
      case GET_SINGLE_SERVICES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };