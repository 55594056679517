import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { postContactUs } from '../../Redux/Actions/contactUs';
import MyLocation from '../Mylocation/MyLocation';
import './contactus.css'
import { useAlert } from 'react-alert';
const ContactUs = () => {
  const {loading,success,contact}=useSelector(state=>state.contactReducers)
  const alert=useAlert()
  
 const options={

 }
    const dispatch=useDispatch()
    const signUpValidation=Yup.object({
          name:Yup.string().min(3,'Name must have at least 3 characters').max(40,'Name not more than 40 characters')
          .required('Name is Required'),
        
          email:Yup.string().email().matches(/@[^.]*\./).required('Email is Required'),
          subject: Yup.string().required('Subject is required'),
          message: Yup.string().required('Password is required'),
          phoneNumber: Yup.string().required('Phone Number is required'),
     
          });
          let initialValues={
            name:'',email:'',subject:'',message:'',phoneNumber:''
          }
          const {values,handleChange,handleBlur,handleReset,handleSubmit,errors,touched}=useFormik({
            initialValues:initialValues,
            validationSchema:signUpValidation,
            onSubmit:(values)=>{
            dispatch(postContactUs({values:values}))
            }
          }) 
          useEffect(()=>{
           
            if(contact!=='',loading===false && success===true){
              alert.success('Your Message has been successfully sent')
            }
    
          },[contact,loading,success,alert])
  return (
    <div>
        
       <div className="contact" id="contact">
            <div className="container-fluid">
                <div className="section-header">
                    <h3 className='text-light'>Contact Us</h3>
                    <p>
                                            </p>
                </div>

                <div className="row w-100 align-items-center">
                    <div className="col-md-6">
                        <div className="form">
                        <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                      <label htmlFor='name'>Name</label>
 <input type='text' placeholder={`Please Enter name`} className="form-control" 
value={values.name} onChange={handleChange} onBlur={handleBlur} name='name'/>
{errors.name && touched.name?(
<p className='form-error'>{errors.name}</p>
):null}
                                    </div>
                                    <div className="form-group col-12">
                                    <label htmlFor='email'>Email</label>
                                    <input type='email' placeholder={`Please Enter email`} className='form-control' 
value={values.email} onChange={handleChange} onBlur={handleBlur} name='email'/>
{errors.email && touched.email?(
<p className='form-error'>{errors.email}</p>
):null}
                                    </div>
                                </div>
                                <div className="form-group">
                                <label htmlFor='Phone'>Phone Number</label>
                                <input type='text' placeholder={`Please Enter Phone Number`} className='form-control' 
value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} name='phoneNumber'/>
{errors.phoneNumber && touched.phoneNumber?(
<p className='form-error'>{errors.phoneNumber}</p>
):null}
                                </div>
                                <div className="form-group">
                                <label htmlFor='subject'>Subject</label>
                                <input type='text' placeholder={`Please Enter Subject`} className='form-control' 
value={values.subject} onChange={handleChange} onBlur={handleBlur} name='subject'/>
{errors.subject && touched.subject?(
<p className='form-error'>{errors.subject}</p>
):null}
                                </div>
                                <div className="form-group">
                                <label htmlFor='message'>Message</label>
                                <textarea type='text' placeholder={`Please Enter Message`} className='form-control' 
value={values.message} onChange={handleChange} onBlur={handleBlur} name='message'/>   
{errors.message && touched.message?(
<p className='form-error'>{errors.message}</p>
):null}                            </div>
                                <div><button type="submit">Send Message</button></div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6"> <MyLocation/>
                        
                    </div>
                
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default ContactUs
