import { GET_DISTRICT_FAIL, GET_DISTRICT_REQUEST, GET_DISTRICT_SUCCESS } from "../Constants/districtConstant";

export const districtReducers = (state = {getDistricts: [] }, action) => {
    switch (action.type) {
      case GET_DISTRICT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_DISTRICT_SUCCESS:
        // console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          getDistricts: action.payload,
        };
      case GET_DISTRICT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };