import { GET_DETAILS_FAIL, GET_DETAILS_REQUEST, GET_DETAILS_SUCCESS } from "../Constants/detailsConstant";


export const detailsReducers = (state = { details: [] }, action) => {
    switch (action.type) {
      case GET_DETAILS_REQUEST:
        return {
          ...state,
          loading: true,
          success:false
        };
      case GET_DETAILS_SUCCESS:
        console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          success:true,
         details: action.payload,
        };
      case GET_DETAILS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };