import React from 'react'
import './myLocation.css'
const MyLocation = () => {
  return (
    <div>
      
                   
                        <div className="contact-info">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52925.967133192375!2d72.86580042167965!3d33.99579709999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfb2dbad289223%3A0xe7b989e12f4be25c!2sGakhar%20Plaza!5e0!3m2!1sen!2s!4v1694001880622!5m2!1sen!2s" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{border:'0px'}}></iframe>

                       
                    </div>
</div>
  )
}

export default MyLocation
