import { GET_FEATURES_FAIL, GET_FEATURES_REQUEST, GET_FEATURES_SUCCESS } from "../Constants/featureConstants";


export const featureReducers = (state = { feature: [] }, action) => {
    switch (action.type) {
      case GET_FEATURES_REQUEST:
        return {
          ...state,
          loading: true,
          success:false
        };
      case GET_FEATURES_SUCCESS:
        console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          success:true,
          feature: action.payload,
        };
      case GET_FEATURES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };