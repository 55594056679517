import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { getSingleService } from '../../Redux/Actions/servicesAction'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';
import './services.css'
const SingleService = () => {
  const {slug}=useParams()
  const navigate=useNavigate()
  const dispatch=useDispatch()
  // dispatch(getSingleService(slug))
    const {service}=useSelector(state=>state.getSingleServicesReducer)
    const {feature}=useSelector(state=>state.featureReducers)
    const {details}=useSelector(state=>state.detailsReducers)
    const {servicesDetails}=useSelector(state=>state.getServicesDetailsReducers)
    // console.log(details,'single service');
    const handleId=async(id)=>{
     
navigate(`/service/${slug}/${id}`)

    }
    window.scroll(0,0)
  return (
    <div>

      <div className="main-single-service mb-4">
      <div class="section-header">
                    <h2>{`Prime Services ${service?.name}`}</h2>
                </div>
        <div className="single-services mb-3">
        {servicesDetails?.map((item)=>{
    return(
      <>
      <div class="single-service-inner">
              
                 
                            <div class="single-service-card">
                              <div className="service-img-div">
                                    <img src={`/uploads/services/${item.image}`} alt={item.name}/>
                                    </div>
                                    <div className="title-service-div">
                                    <div class="card-title fw-bold" style={{fontSize:'20px',fontFamily:'times'}}>{item.name}</div>
                                    </div>
                       

        </div></div>
      
      </>)})}
        </div>
        <div id="about">
            <div class="container-fluid">
                <div class="section-header">
                    <h2>{`What We Do In ${service?.name}`}</h2>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="welcome m-auto">
                            <p>
                            {service?.details}               </p>
                       
                        </div>
                    </div>
                </div></div></div>
{/* feature section */}


     <div className="container-fluid mb-3" style={{background:'#3F69AA'}}>
      <h2 class="section-title h1 text-light fw-bold section-header" >Our Key Features</h2>
      <div class="feature-div mb-2" >
            {/* <!-- Team member --> */}
    {feature?.map((item,i)=>{
      return(
        <>
                <div class="feature-inner-div mb-1">
               
                       
                           <p> {(i+1)}...{item.list}    </p>  
                                   
            </div>
           
            
        
        </>
      )
    })}
            {/* <!-- ./Team member --> */}

        </div></div>
   
      <section id="team" class="pb-5">
    <div class="container-fluid">
    <div class="section-header">
                    <h2>{`Services In ${service?.name}`}</h2>
                </div>
        <div class="row">
            {/* <!-- Team member --> */}
    {details?.map((item,i)=>{
      return(
        <>
                <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="image-flip" >
                    <div class="mainflip flip-0">
                        <div class="frontside">
                            <div class="card">
                                <div class="card-body text-center mx-auto">
                                    <p>

                                    <img src={`/uploads/pointofsale/${item.image}`} alt={item.tile}/>

                                    </p>
                                    <h4 class="card-title" style={{fontSize:'18px'}}> ({i+1})...{item.title}</h4>
                                    <p class="card-text mx-auto">
                                  <button className='btn btn-primary'>Hover To Read More</button>
                                      </p>
                                                                   </div>
                            </div>
                        </div>
                        <div class="backside">
                            <div class="card">
                                <div class="card-body text-center mt-4">
                                    <h4 class="card-title" style={{fontSize:'18px'}}>{item.title}</h4>
                                    <p class="card-text mx-auto text-center">
                                    {item.details.length>350?<div>{item.details.substring(0,350)}...<br/>
                                    <button className='btn btn-primary mt-3'onClick={()=>handleId(item._id)}>Read More</button> 

                                    </div>:<div>{item.details}</div>}  
                                    
                                      </p>
                                      
                                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
      )
    })}
            {/* <!-- ./Team member --> */}

        </div>
    </div>
</section>
      {/* <div className="name-div">A good website <i class="fas fa-slack-hash    "></i></div>

      <div className="detail-div">
        {details?.map((item,i)=>{
          return(
            <>
            <div className="detail-map-div">
<div className="detial-image-div mt-3">
  <img src={`/uploads/pointofsale/${item.image}`} alt={item.tile}/>
</div>
<div className="detail-title-dic text-center bg-danger mt-3 text-light mb-2">
  <div>({i+1})...{item.title}</div>
</div>
<div className="detail-detail ms-2 me-2">
  {item.details.length>300?<div>{item.details.substring(0,300)}...</div>:<div>{item.details}</div>}
</div>

<div style={{textAlign:'center'}}>
  <button className='btn btn-warning mt-3'onClick={()=>handleId(item._id)}>Read More</button></div>
            </div>
  
            </>
          )
        })}
      </div> */}
     
      <div id="about">
            <div class="container-fluid">
                <div class="section-header">
                    <h2>{`Our Pride In ${service?.name}`}</h2>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="welcome m-auto">
                            <p>
                            {service?.credit}               </p>
                       
                        </div>
                    </div>
                </div></div></div>
                </div>
    </div>
  )
}

export default SingleService
