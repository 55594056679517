import React from 'react'

const About = () => {
    return (
        <div>
 <div id="about">
            <div class="container-fluid">
                <div class="section-header">
                    <h2> What makes us who we are today…</h2>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="welcome m-auto">
                            <p>
                            At Unique SOftware House (ush), We develop innovative and creative products and services 
            that provide total communication and information solutions. 
            Among a plethora of services, web design and development,POS, e-commerce solutions,
             business-to-business applications, business-to-client applications, managed hosting and internet portal management
              are few that we offer. Satisfied clients around the globe bear testimony to the quality of our work.

            As a leader in technology exploring, USH is committed to exporting quality software worldwide.
            The general purpose of USH is to develop and promote advanced information technologies for multi-user 
            operation.

           USH's business philosophy is to assure the highest quality product, total client satisfaction, timely delivery
            of solutions and the best quality/price ratio found in the industry.

            Our emphasis is on offering a high degree of product user friendliness through a positive, creative and focused company staff.
               </p>
                            {/* <a class="btn" href="#">Read More</a> */}
                        </div>
                    </div>
                </div></div></div>
           
                </div>
    )
}

export default About
