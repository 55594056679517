import { GET_STATE_FAIL, GET_STATE_REQUEST, GET_STATE_SUCCESS } from "../Constants/stateConstant";

export const stateReducers = (state = {getAllState: [] }, action) => {
    switch (action.type) {
      case GET_STATE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_STATE_SUCCESS:
        console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          getAllState: action.payload,
        };
      case GET_STATE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };