import { USER_LOCATION_FAIL, USER_LOCATION_REQUEST, USER_LOCATION_SUCCESS } from "../Constants/userLocation";

export const userLocationReducer = (state = { userLocation: {} }, action) => {
    switch (action.type) {
      case USER_LOCATION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case USER_LOCATION_SUCCESS:
        // console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          userLocation: action.payload,
        };
      case USER_LOCATION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };