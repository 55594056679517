import { GET_USER_PROFILE_FAIL, GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, GET_USERS_FAIL, GET_USERS_REQUEST, GET_USERS_SUCCESS, LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGIN_USER_FAIL, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, POST_USER_FAIL, POST_USER_REQUEST, POST_USER_SUCCESS } from "../Constants/userConstants";

export const userPostReducers = (state = { userPost: {} }, action) => {
    switch (action.type) {
      case POST_USER_REQUEST:
        return {
          ...state,
          loading: true,
          success:false
        };
      case POST_USER_SUCCESS:
        // console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          success:true,
          userPost: action.payload,
        };
      case POST_USER_FAIL:
        return {
          ...state,
          loading: false,
          success:false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };
export const allUsersReducers = (state = { allUsers: [] }, action) => {
    switch (action.type) {
      case GET_USERS_REQUEST:
        return {
          ...state,
          loading: true,
          success:false
        };
      case GET_USERS_SUCCESS:
        // console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          success:true,
          allUsers: action.payload,
        };
      case GET_USERS_FAIL:
        return {
          ...state,
          loading: false,
          success:false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };
export const userProfileReducers = (state = { getUserProfile: [] }, action) => {
    switch (action.type) {
      case GET_USER_PROFILE_REQUEST:
        return {
          ...state,
          loading: true,
          success:false
        };
      case GET_USER_PROFILE_SUCCESS:
        // console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          success:true,
          getUserProfile: action.payload,
        };
      case GET_USER_PROFILE_FAIL:
        return {
          ...state,
          loading: false,
          success:false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };

export const loadUserReducers = (state = { userLoad: {} }, action) => {
    switch (action.type) {
      case LOAD_USER_REQUEST:
        return {
          ...state,
          loading: true,
          success:false
        };
      case LOAD_USER_SUCCESS:
        console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          success:true,
          userLoad: action.payload,
        };
      case LOAD_USER_FAIL:
        return {
          ...state,
          loading: false,
          success:false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };
export const loginUserReducers = (state = { userLogin: {} }, action) => {
    switch (action.type) {
      case LOGIN_USER_REQUEST:
        return {
          ...state,
          loading: true,
          success:false
        };
      case LOGIN_USER_SUCCESS:
        // console.log(action.payload,'paylaod');
        
        return {
          loading: false,
          success:true,
      userLogin: action.payload,
        };
      case LOGIN_USER_FAIL:
        return {
          ...state,
          loading: false,
          success:false,
          error: action.payload,
            };
    
      default:
        return state;
    }
  };