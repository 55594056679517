import React, { useEffect, useState } from 'react'
import { getCountries } from '../../Redux/Actions/getCountriesAction'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux'
import { getStates } from '../../Redux/Actions/getStateAction'
import { getdistrict } from '../../Redux/Actions/getDistrictActions'
import * as Yup from "yup";
import { postUser } from '../../Redux/Actions/userActions';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import SideBar from '../Dashboard/SideBar';
import { json } from 'react-router-dom';
import {useAlert} from 'react-alert'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
// name,fname,email,password,active,userImage,fb,insta,yt,github,city,address,role,gender,dob,education,experience,
// userlocation,userIp,latitute,magnitude,

const Register = () => {
  const alert=useAlert()
  const dispatch=useDispatch()
  const [isClicked, setIsClicked] = useState(false);
  const [fileName,setFileName]=useState('')
  const [projectImages,setProjectImages]=useState([])
  const [projectImagesName,setProjectImagesName]=useState([])
  const [projectImagesPreview,setProjectImagesPreview]=useState([])
  const [cvFileName,setCvFileName]=useState('')
  const [userImage,setUserImage]=useState('')
  const [userCv,setUserCv]=useState('')
  const [zipFile,setZipFile]=useState('')
  const [countryId,setCountryId]=useState('')
  const [stateId,setStateId]=useState('')
  const [cityId,setCityId]=useState('')
  const [urls, setUrls]=useState([{urlList:''}])
  const [zipFileName,setZipFileName]=useState('')
  const [skills,setSkills]=useState([{skillList:'',skillLevel:''}])
  const [education,setEducation]=useState([
    {degree:'',passingYear:'',majorSubjects:'',board:'',grade:''}
  ])
  const [experience,setExperience]=useState([
    {post:'',startingDate:'',endingDate:'',company:''}
  ])
const [avatarPreview, setAvatarPreview] = useState("");
const [cvPreview, setCvPreview] = useState("/Profile.png");
  const {allCountries}=useSelector(state=>state.countriesReducers)
  const {getAllState} =useSelector(state=>state.stateReducers)
  const {userLocation} =useSelector(state=>state.userLocationReducer)
  const {getDistricts} =useSelector(state=>state.districtReducers)

const  {userPost,error} =useSelector(state=>state.userPostReducers)
console.log(userPost,'user post');
useEffect(()=>{
  if(error?.status===500){
    alert.error('User Already Exist')
  }else{
    alert.success('User Register Successfully')
  }
},[error,alert])
  const options=['10','20','30','40','50','60','70','80','90','100']
  // console.log(usr,'post user');
  
  const user={name:'sajjad',role:'superAdmin',email:'sajadabbasi55@gmail.com',password:'123456',profession:'seo'}

//  handling project images
  const handleInputChangeProjectImage=(e)=>{
          const files = Array.from(e.target.files);
  
      setProjectImages([]);
      setProjectImagesPreview([]);
  
      files.forEach((file) => {
        const reader = new FileReader();
  
        reader.onload = () => {
          if (reader.readyState === 2) {
            setProjectImagesPreview((old) => [...old, reader.result]);
            setProjectImages((old) => [...old, reader.result]);
          }
        };
  
        reader.readAsDataURL(file);
        let test=[]
        for (let i = 0; i < e.target.files.length; i++) {
          test.push(e.target.files[i].name)
        }
         
        setProjectImagesName(test)
      });
    };


  
  // handle user profile image
  const handleInputChangeImage=(e,i)=>{    
    const reader = new FileReader(); 
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
        setUserImage(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0])
    setFileName(e.target.files[0].name)
  } 

// user cv

  const handleInputChangeCV=(e,i)=>{    
    const reader = new FileReader(); 
    reader.onload = () => {
      if (reader.readyState === 2) {
        setUserCv(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0])
    setCvFileName(e.target.files[0].name)
  } 
  
  // handle projects zip file

  const handleInputChangeZip=(e,i)=>{   
      const files = Array.from(e.target.files);  
      setZipFile([]);
      files.forEach((file) => { 
    const reader = new FileReader(); 
    reader.onload = () => {
      if (reader.readyState === 2) {
        
        setZipFile(reader.result);
      }
    };

    reader.readAsDataURL(file)
    let test=[]
    for (let i = 0; i < e.target.files.length; i++) {
      test.push(e.target.files[i].name)
    }
     
    setZipFileName(test)
})} 
  // console.log(zipFile,'zip name',zipFileName,'zip file name');
  

  // console.log(cvFileName,'cv name', userCv,'user cv');
  
  // handle education carrer
  const handleEducationChange=(e,i)=>{
    // alert('fileName')
      let newForm=[...education]
      newForm[i][e.target.name]=e.target.value
      setEducation(newForm)}
      const addRowEducation=()=>{
        setEducation([...education,{degree:'',passingYear:'',mahorSubject:'',board:'',grade:''}])
  
      }
      const onRemoveEducation=(e)=>{
        // console.log(e,'index');
        const newForm=[...education]
        newForm.splice(e,1)
  setEducation(newForm)}
// education part ended
// professiona carrier


const handleExperienceChange=(e,i)=>{
  // alert('fileName')
    let newForm=[...experience]
    newForm[i][e.target.name]=e.target.value
    setExperience(newForm)}
    const onRemoveExperience=(e)=>{
      // console.log(e,'index');
      const newForm=[...experience]
      newForm.splice(e,1)
  setExperience(newForm)}
    const addRowExperience=()=>{
      setExperience([...experience,{post:'',startingYear:'',endingYear:'',jobDescription:'',company:''}])

    }
 
// skills adding
  const handleUrlChange=(e,i)=>{
    // alert('fileName')
      let newForm=[...urls]
      newForm[i][e.target.name]=e.target.value
      setUrls(newForm)}

      const addUrlRow=()=>{
        setUrls([...urls,{list:''}])
  
      }
      const onUrlRemove=(e)=>{
        // console.log(e,'index');
        const newForm=[...urls]
        newForm.splice(e,1)
  setUrls(newForm)}

  // handle project urls
  const handleSkillChange=(e,i)=>{
    // alert('fileName')
      let newForm=[...skills]
      newForm[i][e.target.name]=e.target.value
      setSkills(newForm)}

      const addSkillRow=()=>{
        setSkills([...skills,{list:''}])
  
      }
      const onSkillRemove=(e)=>{
        // console.log(e,'index');
        const newForm=[...skills]
        newForm.splice(e,1)
  setSkills(newForm)}

      
  





  const signUpValidation=Yup.object({
    name:Yup.string().min(3,'Name must have at least 3 characters').max(40,'Name not more than 40 characters')
    .required('Name is Required'),
  address:Yup.string().min(10,'Address must have at least 3 characters').max(60,'Address not more than 40 characters')
    .required('Address is Required'),
    category:Yup.string().required('category is required'),
    phoneNumber:Yup.string().required('Phone Number is required'),
  whatsapp:Yup.string().required('WhatsApp is required'),
   dob:Yup.string().required('age is required'),
  //  fileName:Yup.string().required('Profile Image is required'),
   describe:Yup.string().min(150,'description must be more than 150 words').required('Profile Image is required'),
    Nic:Yup.string().required('NIC is required'),
    email:Yup.string().email().matches(/@[^.]*\./),
     password: Yup.string().required('Password is required'),
      cpassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
    });
  
  // console.log(getAllState,'all countries');
  let initialValues={
    name:'',email:'',password:'', cpassword:'',facebook:'',insta:'',youtube:'',github:'',
    address:'',gender:'',dob:'',category:'',phoneNumber:'',Nic:'',whatsapp:'',describe:''
  }
  const {values,handleChange,handleBlur,handleReset,handleSubmit,errors,touched}=useFormik({
    initialValues:initialValues,
    validationSchema:signUpValidation,
    onSubmit:(values)=>{
      console.log(values,'values');
      
   const{name,email,password,facebook,insta,youtube,github,profession,teamLeader,phoneNumber,Nic,describe,
    address,gender,dob,category,whatsapp}=values
      dispatch(postUser({zipFile,zipFileName,fileName,cvFileName,userImage,userCv,
        name,email,password,facebook,insta,youtube,github,describe,
    address,gender,dob,countryId,stateId,cityId,category,education,experience,
    projectImagesName,projectImages,urls,skills,profession,teamLeader,phoneNumber,Nic,whatsapp
      }))
    }
  })  
  // console.log(education,'education');


    const handleChange1=(e)=>{
      console.log(e,'pppppppppp');
      
let count=e.split(',')
let val=count?.[0]
let abc=count?.[1]
setCountryId(abc)

     dispatch(getStates(val))
      
    }
    const handleGetCities=(e)=>{
    let xyz=e.split(',');
    xyz=xyz?.[2]
    setStateId(xyz)
      let abc=[e]        
dispatch(getdistrict(abc))
    }



  return (
    <div>

  <form onSubmit={handleSubmit}>
  <div className="service-form">
    <h3 style={{color:'white'}} className='mt-3'>Job Application Form</h3>
    {/* <SideBar/> */}
    <div className="register-inner-div">

      {/* choose team Leader */}
      {/* <div className="div-1">
      <label htmlFor="team leader" className='label-class'>Team Leader</label>
      <input type='text' className='input-class' readOnly
value={user.name} onChange={handleChange} onBlur={handleBlur} name='teamLeader'/>
      </div> */}
{/* choose category */}
      {/* <div className="div-1">
      <label htmlFor="profession" className='label-class'>Profession</label>
      <input type='text' className='input-class' readOnly
value={user.profession} onChange={handleChange} onBlur={handleBlur} name='profession'/>
      </div> */}

{/* country selection */}

    <div className="div-1">
    <label htmlFor="name" className='label-class'>choose Country</label>
         <select name='country' onChange={(e)=>{handleChange1(e.target.value)}}>
<option value="">select country</option>
{allCountries?.map((item)=>{
        return(
          <>
<option value={[item.countryCode,item.name]}>{item.name}</option>       

          </>
        )
        
      })} 
      
      </select>
      </div>
      {/* state selection */}
      <div className="div-1">
      <label htmlFor="name" className='label-class'>choose state</label>
         <select onChange={(e)=>handleGetCities(e.target.value)}>
<option value="">select State</option>
{getAllState?.map((item)=>{
  const {isoCode,countryCode,name,_id}=item
        return(
          <>
        
<option value={[isoCode,countryCode,name]}>{name}</option>       

          </>
        )
        
      })} </select>
      </div>
      {/* district selection */}
      <div className="div-1">
      <label htmlFor="District" className='label-class'>choose district</label>
         <select onChange={(e)=>setCityId(e.target.value)} name='countryId'>
<option value="">select district</option>
{getDistricts?.map((item)=>{
        return(
          <>
        
<option value={item.name}>{item.name}</option>       

          </>
        )
        
      })} </select>
      </div>
    
{/* name */}
<div className="div-1">
<label htmlFor="name" className='label-class'>name<span className='star'>*</span></label>
<input type='text' placeholder={`Please Enter name`} className='input-class' 
value={values.name} onChange={handleChange} onBlur={handleBlur} name='name'/>
{errors.name && touched.name?(
<p className='form-error'>{errors.name}</p>
):null}
</div>
{/* email */}
<div className="div-1">
<label htmlFor="email" className='label-class'>email<span className='star'>*</span></label>
<input type='email' placeholder={`Please Enter email`} className='input-class' 
value={values.email} onChange={handleChange} onBlur={handleBlur} name='email'/>
{errors.email && touched.email?(
<p className='form-error'>{errors.email}</p>
):null}
</div>
{/* password */}
<div className="div-1">
<label htmlFor="password" className='label-class'>password<span className='star'>*</span></label>
<input type='password' placeholder={`Please Enter password`} className='input-class' 
value={values.password} onChange={handleChange} onBlur={handleBlur} name='password'/>
{errors.password && touched.password?(
<p className='form-error'>{errors.password}</p>
):null}
</div>
{/* confirm password */}
<div className="div-1">
<label htmlFor="cpassword" className='label-class'>confirm password<span className='star'>*</span></label>
<input type='password' placeholder={`Please Enter cpassword`} className='input-class' 
value={values.cpassword} onChange={handleChange} onBlur={handleBlur} name='cpassword'/>
{errors.cpassword && touched.cpassword?(
<p className='form-error'>{errors.cpassword}</p>
):null}
</div>
{/* phone number */}
<div className="div-1">
<label htmlFor="phoneNumber" className='label-class'>phoneNumber<span className='star'>*</span></label>
<input type='text' placeholder={`Please Enter phoneNumber`} className='input-class' 
value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} name='phoneNumber'/>
{errors.phoneNumber && touched.phoneNumber?(
<p className='form-error'>{errors.phoneNumber}</p>
):null}
</div>
{/* Nic */}
<div className="div-1">
<label htmlFor="Nic" className='label-class'>NIC<span className='star'>*</span></label>
<input type='text' placeholder={`Please Enter nic`} className='input-class' 
value={values.Nic} onChange={handleChange} onBlur={handleBlur} name='Nic'/>
{errors.Nic && touched.Nic?(
<p className='form-error'>{errors.Nic}</p>
):null}
</div>
{/* What app */}
<div className="div-1">
<label htmlFor="whatsapp" className='label-class'>whatsapp<span className='star'>*</span></label>
<input type='text' placeholder={`Please Enter whatsapp`} className='input-class' 
value={values.whatsapp} onChange={handleChange} onBlur={handleBlur} name='whatsapp'/>
{errors.whatsapp && touched.whatsapp?(
<p className='form-error'>{errors.whatsapp}</p>
):null}
</div>
{/* face book */}
<div className="div-1">
<label htmlFor="facebook" className='label-class'>facebook</label>
<input type='text' placeholder={`Please Enter facebook`} className='input-class' 
value={values.facebook} onChange={handleChange} onBlur={handleBlur} name='facebook'/>
{errors.facebook && touched.facebook?(
<p className='form-error'>{errors.facebook}</p>
):null}
</div>
{/* Insta gram */}

<div className="div-1">
<label htmlFor="insta" className='label-class'>insta</label>
<input type='text' placeholder={`Please Enter insta`} className='input-class' 
value={values.insta} onChange={handleChange} onBlur={handleBlur} name='insta'/>
{errors.insta && touched.insta?(
<p className='form-error'>{errors.insta}</p>
):null}
</div>
{/* youtube */}
<div className="div-1">
<label htmlFor="youtube" className='label-class'>youtube</label>
<input type='text' placeholder={`Please Enter youtube`} className='input-class' 
value={values.youtube} onChange={handleChange} onBlur={handleBlur} name='youtube'/>
{errors.youtube && touched.youtube?(
<p className='form-error'>{errors.youtube}</p>
):null}
</div>
{/* github     */}
<div className="div-1">
<label htmlFor="github" className='label-class'>github</label>
<input type='text' placeholder={`Please Enter github`} className='input-class' 
value={values.github} onChange={handleChange} onBlur={handleBlur} name='github'/>
{errors.github && touched.github?(
<p className='form-error'>{errors.github}</p>
):null}
</div>
{/* category */}
<div className="div-1">
<label htmlFor="category" className='label-class'>Category<span className='star'>*</span></label>
<select name="category" id="" onChange={handleChange} onBlur={handleBlur}>
<option value=''>Select Category</option>
<option value="webDevelopment">Web Developer</option>
<option value="softwareDevelopment">Software Developer</option>
<option value="android Development">Android Developer</option>
<option value="searchEnginOptimization">SEO</option>
<option value="videoEditing">Video Editor</option>
<option value="website Designer">Web Designer</option>


</select>
{errors.category && touched.category?(
<p className='form-error'>{errors.category}</p>
):null}
{/* gender */}
</div>
<div className="div-1">
<label htmlFor="gender" className='label-class'>Gender</label>
<select name="gender" id="" onChange={handleChange} onBlur={handleBlur}>
<option value=''>Select Gender</option>
<option value="male">Male</option>
<option value="female">Female</option>
<option value="notmention">Not Mentioned</option>
</select>
{/* date of birth`` */}
</div>
<div className="div-1">
<label htmlFor="dob" className='label-class'>DOB<span className='star'>*</span></label>
<input type='date' onChange={handleChange} onBlur={handleBlur} name='dob'/>
{errors.dob && touched.dob?(
<p className='form-error'>{errors.dob}</p>
):null}
</div>
{/* addrees */}
<div className="div-1">
<label htmlFor="address" className='label-class'>address<span className='star'>*</span></label>
<textarea type='text' placeholder={`Please Enter address`} className='input-class' 
value={values.address} onChange={handleChange} onBlur={handleBlur} name='address' style={{height:'55px'}}/>
{errors.address && touched.address?(
<p className='form-error'>{errors.address}</p>
):null}
</div>
{/* <div className="div-1"> */}
        {/* handle url */}
{urls?.map((item,i)=>{
            {/* console.log(item,'item'); */}
            
            return(
                <>
                <div className="div-1">
                <label htmlFor="feature">Add Project Url</label>
                 <p style={{color:'white', fontWeight:'bold'}}>{i+1}</p>: &nbsp;
                 <input value={item.urlList} name='urlList' placeholder='https://www.uniquesoftwarehouse.com' className='w-75'
                   onChange={(e)=>handleUrlChange(e,i)}  style={{width:'80%'}}/>
                <button className='btn btn-danger' onClick={()=>onUrlRemove(i)} ><DeleteIcon/></button>
                <button className='btn btn-warning mt-2' onClick={addUrlRow}><AddIcon/></button>
                </div>
                </>
            )
        })}
   
    

{/* </div> */}

{/* profile image */}
<div className="div-1">
<label htmlFor="detailImage">Profile Image<span className='star'>*</span></label>
<input type='file'
            onChange={(e)=>{handleInputChangeImage(e);
                  }} />
                  {avatarPreview!==''?<img src={avatarPreview} alt='' style={{height:'100px',width:'100px'}}/>:null}
{fileName===''?<div className='text-danger fw-bold'>Image is required</div>:null}        
</div>
{/* curriculam vitea`` */}
<div className="div-1">
<label htmlFor="detailImage">Upload Cv</label>
<input type='file' name='cv' accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
            onChange={(e)=>{handleInputChangeCV(e);
                  }} />
                  {/* <img src={cvPreview} alt='' style={{height:'100px',width:'100px'}}/> */}
</div>
{/* project Image */}

<div className="div-1">
<label htmlFor="detailImage">Upload Project images</label>
<input type='file' name='projectImage' accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
            onChange={(e)=>{handleInputChangeProjectImage(e)}}  multiple/>
                  {/* <img src={cvPreview} alt='' style={{height:'100px',width:'100px'}}/> */}
             
</div>
{/* project zipFiles */}
{/* <div className="div-1">  
<label htmlFor="detailImage">Upload zip Project</label>
<input type="file" multiple accept=".zip,.rar,.7zip" onChange={(e)=>handleInputChangeZip(e)}/>
  </div> */}



</div>

<div className="hr-div"></div>
<h4 className='text-light'>Educational Career</h4>


{education?.map((item,i)=>{
  return(
    <>
    <div className="educataion-div">
      <div className="div-1">
      <label htmlFor="detailImage">#</label> 
      <input value={i+1}/>
      </div>
    <div className="div-1">
<label htmlFor="Degree">Degree</label>
<input value={item.degree} name='degree' placeholder='Degree Name' className='w-100'
                   onChange={(e)=>handleEducationChange(e,i)} />
                   </div>
                   <div className="div-1">
                   <label htmlFor="passingYear">Passing Year</label>
                   <input value={item.passingYear} type='date' name='passingYear' placeholder='Passing Year' className='w-100'
                   onChange={(e)=>handleEducationChange(e,i)} />
                   </div>
<div className="div-1">      
<label htmlFor="majorSubject">Major Subject</label>
   <input value={item.majorSubjects} name='majorSubjects' placeholder='Major subjects' className='w-100'
                   onChange={(e)=>handleEducationChange(e,i)} /></div>


<div className="div-1">    
<label htmlFor="Grade">Grade</label>
  <input value={item.grade} name='grade' placeholder='Grade' className='w-100'
                   onChange={(e)=>handleEducationChange(e,i)} /></div>

<div className="div-1">   
<label htmlFor="Board/Uni">Board/Uni</label>
   <input value={item.board} name='board' placeholder='Board/Uni' className='w-100'
                   onChange={(e)=>handleEducationChange(e,i)} /></div>
              
                  
                     
                     <div className="div-1 add-row d-flex flex-row text-center"> 
                
 <div> {education.length===1?null:<button className='btn btn-danger w-100' onClick={()=>onRemoveEducation(i)} >
 <DeleteIcon/>
 </button>} </div>

  <div><button className='btn btn-warning  mt-2 w-100' onClick={addRowEducation}>
    <AddIcon/>
    </button></div>
                   </div>
                   </div> 
                          
    </>
  )

})}
                   <div className="hr-div"></div>
                  <h4 className='text-light'> Experience Career</h4>


{
experience?.map((item,i)=>{
  return(
    <>
    <div className="educataion-div">
      <div className="div-1">
      <label htmlFor="detailImage">#</label> 
      <input value={i+1}/>
      </div>
    <div className="div-1">
<label htmlFor="post">Post Name</label>
<input value={item.post} name='post' placeholder='Your Post' className='w-100'
                   onChange={(e)=>handleExperienceChange(e,i)} />

                   </div>

                   <div className="div-1">
                   <label htmlFor="Starting Year">Starting Date</label>
                 <input value={item.startingDate} type='date' name='startingDate' placeholder='Starting Year' className='w-100'
                   onChange={(e)=>handleExperienceChange(e,i)} />
                   </div>
<div className="div-1">      
<label htmlFor="Ending Year">Ending Date</label>
<input value={item.endingDate} name='endingDate' type='date' placeholder='Ending Date' className='w-100'
                   onChange={(e)=>handleExperienceChange(e,i)} /></div>


<div className="div-1">    
<label htmlFor="compnay">Company</label>
<input value={item.company} name='company' placeholder='compnay Name' className='w-100'
                   onChange={(e)=>handleExperienceChange(e,i)} /></div>

                  
                     
                     <div className="div-1 add-row d-flex flex-row text-center"> 
                
 <div> {education.length===1?null:<button className='btn btn-danger w-100' onClick={()=>onRemoveExperience(i)} >
 <DeleteIcon/>
  </button>} </div>

  <div><button className='btn btn-warning  mt-2 w-100' onClick={addRowExperience}><AddIcon/>
    </button></div>
                   </div>
                   </div> 
                          
    </>
  )

})}
                   
<div className="hr-div"></div>

{/* professional carrier */}
<h4 className='text-light'>Add Skill<span className='star'>*</span></h4>
{/* skills */}
        {/* <div className="div-1"> */}
        
{skills?.map((item,i)=>{
            {/* console.log(item,'item'); */}
            
            return(
                <>
                <div className='div-1 d-flex flex-row'>
      
                      {i+1} <input value={item.skillList} name='skillList' placeholder='please enter skill'
                       className='w-50'
                   onChange={(e)=>handleSkillChange(e,i)} />
                   
                  <div className="w-25"> 
                   <select id="" value={item.skillLevel} name='skillLevel' onChange={(e)=>handleSkillChange(e,i)}>
                   <options value='50'>50%</options>
                 {options?.map((item)=>{
                      
                  return(
                    <>
                    
                    <option value={item}>{item}%</option>
               
                    </>
                  )
                 })}
                   </select></div>
                   
                                    {skills.length!==1?<button className='btn btn-danger h-50 ' 
                  onClick={()=>onSkillRemove(i)} ><DeleteIcon/></button>:null} 
                   
                   <button className='btn btn-warning ms-1  h-50' onClick={addSkillRow}><AddIcon/></button>
                   </div>
                </>
            )
        })}


{/* </div> */}
<div className="div-1">
<label htmlFor="describe" className='label-class'>Describe Yourself<span className='star'>*</span></label>
<textarea type='text' placeholder={`Describe Yourself`} className='input-class' 
value={values.describe} onChange={handleChange} onBlur={handleBlur} name='describe' style={{height:'100px'}}/>
{errors.describe && touched.describe?(
<p className='form-error'>{errors.describe}</p>
):null}
</div>


</div>

<button className='btn btn-success mb-3' type='submit'>Submit</button>
{/* <button disabled={isClicked} onClick={() => setIsClicked(true)}  */}
{/* className='btn btn-success mb-3' type='submit'>Submit</button> */}

</form>

    </div>
  )
}

export default Register
