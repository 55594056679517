import React from 'react'
import { useSelector } from 'react-redux'
import RestoreIcon from '@mui/icons-material/Restore';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import './ourStory.css'
const OurStory2 = () => {
    const { wellcome } = useSelector(state => state.wellcomeReducers)
    let story = 'Unique Software House (USH) is known as the well-established software company in Pakistan since 2017. USH started journey on September 2017 with an ambition to provide best-quality website development in Pakistan and develop a trustworthy strategic relationship. Due to new inventions in technology, all barriers of communication are eliminated and the world has become a global village.Unique Software House (USH) is well respected and considered as the top-ranked software company in Pakistan.Unique Software House (USH) is providing a wide range of services including website development, web designing, mobile apps development, search engine optimization (SEO), digital marketing including social media marketing (SMM) ,software development and much more.'
    let mission = 'Exceed clients expectations by going beyond software company to provide best Web service solutions that transform data into knowledge, enabling them to solve their problems.USH Vision and Mission both are inspiring to accomplish goal. To grow current position and get a larger market as well as to provide the simple IT solutions to client. We understand the client’s requirement, analyse it and provide the best IT solutions. We satisfy our clients by our services and implements it with more effictive software solution strategies.'
    let vision = 'Our Vision is to be a leading Web Solution company in IT sector and progress in our current position in market. We know that Customer’s growth is our growth, so we commit our customers to help in achieving their business goals. We believe in work with the accuracy and best quality. We want to be known as the reliable, innovative and user friendly software service provider in IT industry.'
    return (
        <div>
            <div id="about" className='our-story-2'>
            <div class="section-header mt-2">
            <h2 className='text-light'> Our Success Keys</h2>
            <p>
             </p>          </div>
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-md-4 about-col">
                            <div class="about-content">
                                <i><RestoreIcon /></i>
                                <h2>Our Story</h2>

                             <p>{story}</p>

                            </div>
                        </div>
                        <div class="col-md-4 about-col">
                            <div class="about-content">
                                <i><TrackChangesIcon /></i>
                                <h2 className='story'>Our Mission</h2>
                                
                                <p>{mission}</p> 
                            </div>
                        </div>
                        <div class="col-md-4 about-col">
                            <div class="about-content">
                                <i><RemoveRedEyeIcon /></i>
                                <h2>Our Vision</h2>
                               <p>{vision}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurStory2
